import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import notFoundImage from "../../assets/not-found.svg";
import Loading from "../../components/Loaging";
import api from "../../service/api";
import formatDate from "../../utils/formatDate";
import handleMessageError from "../../utils/handleMessageError";
import { Container, Table } from "./styles";

interface IOrderData {
  id: number;
  infNFe_id: string;
  industry_id: string;
  customer_id: string;
  shipping_id: string;
  created_at: string;
  industry: {
    name: string;
  };
  product: {
    id: number;
    name: string;
    quantity: number;
    created_at: string;
  };
  customer: {
    name: string;
  };
  shipping: {
    id: string;
    driver_id: number;
    vehicle_id: number;
    loaded_at: string;
    created_at: string;
    updated_at: string;
    driver?: {
      cpf: string;
      name: string;
      uf: string;
      city: string;
      address: string;
    };
    vehicle: {
      placa: string;
      uf: string;
      rntc: string;
    };
    address: {
      logradouro: string;
      county: string;
      uf: string;
    };
  };
}

interface IDemandData {
  id: string;
  pedido_id: string;
  type_product: string;
  quantity: string;
  created_at: string;
  created_at_formatted: Date;
  updated_at: string;
  balance: {
    request: number;
    loaded: number;
  };
  orders: IOrderData[];
}

interface IRouteParams {
  id: string;
}

const DemandOrders: React.FC = () => {
  const [demand, setDemand] = useState<IDemandData>();

  const { id } = useParams() as IRouteParams;

  useEffect(() => {
    api
      .get(`/demands/${id}/orders`)
      .then(response => {
        setDemand(response.data);
      })
      .catch(err => {
        toast.error(`Erro ao carregar dados. ${handleMessageError(err)}`);
      });
  }, [id]);

  if (!demand) {
    return <Loading message="Buscando carregamentos..." />;
  }

  return (
    <Container>
      <header>
        <p>
          Número do pedido: <strong>{demand.pedido_id}</strong>
        </p>
        <p>
          Tipo do produto: <strong>{demand.type_product}</strong>
        </p>
        <p>
          Cadastrado em: <strong>{formatDate(demand.created_at)}</strong>
        </p>
        <p>
          Comprado: <strong>{demand.balance.request} TN</strong>
        </p>
        <p>
          Carregado: <strong>{demand.balance.loaded} TN</strong>
        </p>
        <p>
          Notas cadastradas: <strong>{demand.orders.length}</strong>
        </p>
      </header>
      <main>
        {demand.orders.length === 0 ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img src={notFoundImage} alt="not found" width={120} height={120} />
            <h2>Nenhuma nota fiscal cadastrada</h2>
          </div>
        ) : (
          <Table>
            <thead>
              <tr>
                <th rowSpan={2}>Industria</th>
                <th rowSpan={2}>Cliente</th>
                <th colSpan={3}>Endereço de entrega</th>

                <th rowSpan={2}>Placa do veículo</th>
                <th rowSpan={2}>Carregado em</th>
                <th rowSpan={2}>Quantidade carregada (TN)</th>
              </tr>
              <tr>
                <th>Estado</th>
                <th>Município</th>
                <th>Logradouro</th>
              </tr>
            </thead>
            <tbody>
              {demand.orders.map(order => (
                <tr key={order.id}>
                  <td>{order.industry.name}</td>
                  <td>{order.customer.name}</td>
                  <td>{order.shipping.address.uf}</td>
                  <td>{order.shipping.address.county}</td>
                  <td>{order.shipping.address.logradouro}</td>
                  <td>
                    {order.shipping.vehicle.uf} - {order.shipping.vehicle.placa}
                  </td>
                  <td>{formatDate(order.shipping.loaded_at)}</td>
                  <td>{order.product.quantity}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </main>
    </Container>
  );
};

export default DemandOrders;
