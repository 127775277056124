import React, { useCallback, useEffect, useState } from "react";
import { RiExternalLinkLine } from "react-icons/ri";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import notFoundImage from "../../../assets/not-found.svg";
import api from "../../../service/api";
import formatDate from "../../../utils/formatDate";
import formatNumber from "../../../utils/formatNumber";
import handleMessageError from "../../../utils/handleMessageError";
import { Container, Table, Link, Status } from "./styles";

interface ICustomerData {
  id: string;
  cpf: string;
  name?: string;
  email?: string;
  phone?: string;
  address_id?: number;
  created_at: string;
  updated_at: string;
}

interface IDemandData {
  id: number;
  pedido_id: string;
  type_product: string;
  quantity: number;
  amount: number;
  customer: ICustomerData;
  pedido_created_at: string;
  created_at: string;
  updated_at: string;
  order_total: number;
  open: string;
}

interface IIndustryData {
  id: string;
  cnpj: string;
  name: string;
  demands: IDemandData[];
  created_at: string;
}

interface IRouteParams {
  id: string;
}

const ReportIndustry: React.FC = () => {
  const [industry, setIndustry] = useState<IIndustryData>();
  const [industryFiltered, setIndustryFiltered] = useState<IIndustryData>();

  const { id } = useParams() as IRouteParams;
  const { search } = useLocation();
  const year = search.split("=")[1];

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get(`/industries/${id}/reports${search}`);

        // const industries_formatted = response.data.industries.map(
        //   (industry: any) => ({
        //     ...industry,
        //     cnpj: formatNumber({ data: industry.cnpj, type: "cnpj" }),
        //     demands: Intl.NumberFormat("pt-br", {}).format(industry.demands),
        //     demand_total: Intl.NumberFormat("pt-br", {}).format(
        //       industry.demand_total,
        //     ),
        //     order_total: Intl.NumberFormat("pt-br", {}).format(
        //       industry.order_total,
        //     ),
        //     balance: Intl.NumberFormat("pt-br", {}).format(industry.balance),
        //   }),
        // );

        setIndustry(response.data);
        setIndustryFiltered(response.data);
      } catch (err) {
        toast.error(
          `Erro ao carregar informações. Favor, tente novamente${handleMessageError(
            err,
          )}`,
        );
      }
    }

    loadData();
  }, [id, search]);

  const handleChangeStatus = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const status = e.target.value as "both" | "true" | "false";

      if (!industry) {
        return;
      }

      if (status === "both") {
        setIndustryFiltered(industry);
      } else {
        const demands_filtered = industry.demands.filter(
          demand => demand.open === status,
        );

        setIndustryFiltered({
          ...industry,
          demands: demands_filtered,
        });
      }
    },
    [industry],
  );

  if (!industry) {
    return (
      <Container>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img src={notFoundImage} alt="not found" width={120} height={120} />
          <h2>Nada cadastrado ainda</h2>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <header>
        <h1>Pedidos da {industry.name || "Sem nome ainda"}</h1>
        <div>
          <p>
            Ano de referência: <strong>{year}</strong>
          </p>
          <Status>
            <label htmlFor="status">Situação: </label>
            <select name="status" id="status" onChange={handleChangeStatus}>
              <option value="both">Ambos</option>
              <option value="true">Com saldo</option>
              <option value="false">Sem saldo</option>
            </select>
          </Status>
        </div>
      </header>

      <main>
        {industry.demands.length === 0 ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img src={notFoundImage} alt="not found" width={120} height={120} />
            <h2>Nenhum pedido cadastrado cadastrado</h2>
          </div>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>Número do pedido </th>
                <th>CPF || Cliente </th>
                <th>Data do pedido</th>
                <th>Quantidade (TN) total pedido</th>
                <th>Quantidade (TN) total carregado</th>
                {/* <th>Saldo (TN)</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {industryFiltered?.demands.map(demand => (
                <tr key={demand.id}>
                  <td>{demand.pedido_id}</td>
                  <td>
                    {formatNumber({
                      data: demand.customer.cpf,
                      type: "cpf",
                    })}{" "}
                    || {demand.customer.name || "Sem nome ainda"}
                  </td>
                  <td>{formatDate(demand.pedido_created_at.split("T")[0])}</td>
                  <td>{demand.quantity}</td>
                  <td>{demand.order_total}</td>
                  {/* <td>{demand.balance}</td> */}
                  <td>
                    <Link
                      to={`/reports/industries/${id}/orders/${demand.pedido_id}?year=${year}`}
                    >
                      <span>Visualizar os carregamentos</span>
                      <RiExternalLinkLine />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </main>
    </Container>
  );
};

export default ReportIndustry;
