import styled from "styled-components";

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 800px;

  margin: 8px auto;
  padding: 16px;
  border-radius: 8px;

  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.4);

  form {
    display: flex;

    max-width: 940px;

    div + div {
      margin-left: 8px;
    }

    button {
      padding: 8px 16px;
      border: 0;
      align-self: flex-end;
      background: #c7c08c;
      color: #3b9974;

      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;

      margin-left: 32px;
    }
  }
`;
