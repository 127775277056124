import { Link as LinkRouter } from "react-router-dom";

import styled from "styled-components";

export const Container = styled.aside`
  color: #fff;

  max-width: 224px;

  header {
    display: flex;
    justify-content: center;

    margin: 24px 0;
    padding: 0 8px 24px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

    p {
      font-size: 24px;
    }
  }

  div {
    margin: 24px 0 8px;
    padding-top: 8px;

    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
`;

export const Link = styled(LinkRouter)`
  display: flex;
  align-items: center;

  padding: 8px;

  text-decoration: none;

  transition: opacity 0.2s;

  svg {
    color: #c7c08c;
    font-size: 24px;
  }

  p {
    margin-left: 8px;
    color: #fff;
  }

  &:hover {
    opacity: 0.8;
  }

  & + a {
    margin-top: 8px;
  }
`;

export const Footer = styled.footer`
  border-top: 1px solid rgba(255, 255, 255, 0.4);

  button {
    display: flex;
    align-items: center;

    padding: 8px;

    text-decoration: none;

    transition: opacity 0.2s;

    background: transparent;
    border: 0;

    margin-top: 8px;

    svg {
      color: #c7c08c;
      font-size: 24px;
    }

    p {
      margin-left: 8px;
      color: #fff;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;
