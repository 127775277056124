import React, { useCallback, useEffect, useState } from "react";
import { RiExternalLinkLine } from "react-icons/ri";
import Lottie from "react-lottie";
import { toast } from "react-toastify";

import loadingData from "../../assets/building-report.json";
import notFoundImage from "../../assets/not-found.svg";
import api from "../../service/api";
import formatNumber from "../../utils/formatNumber";
import handleMessageError from "../../utils/handleMessageError";
import { Container, Table, Link } from "./styles";

interface IIndustryData {
  id: string;
  cnpj: string;
  name: string;
  demands: string;
  demand_total: string;
  order_total: string;
  balance: string;
}

const Home: React.FC = () => {
  const [industries, setIndustries] = useState<IIndustryData[]>([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    try {
      const response = await api.get(`/dashboard/${year}`);

      const industries_formatted = response.data.industries.map(
        (industry: any) => ({
          ...industry,
          cnpj: formatNumber({ data: industry.cnpj, type: "cnpj" }),
          demands: Intl.NumberFormat("pt-br", {}).format(industry.demands),
          demand_total: Intl.NumberFormat("pt-br", {}).format(
            industry.demand_total,
          ),
          order_total: Intl.NumberFormat("pt-br", {}).format(
            industry.order_total,
          ),
          balance: Intl.NumberFormat("pt-br", {}).format(industry.balance),
        }),
      );

      setIndustries(industries_formatted);
      setYears(response.data.years);
    } catch (err) {
      toast.error(
        `Erro ao carregar informações. Favor, tente novamente${handleMessageError(
          err,
        )}`,
      );
    } finally {
      setLoading(false);
    }
  }, [year]);

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [loadData]);

  if (loading) {
    return (
      <Container>
        <header>
          <h2 style={{ textAlign: "center", opacity: 0.6 }}>
            Gerando relatório...
          </h2>
        </header>
        <div
          style={{
            width: "100%",
            maxWidth: 704,
            margin: "auto",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            isClickToPauseDisabled={true}
            style={{
              cursor: "auto",
            }}
          />
        </div>
      </Container>
    );
  }

  if (industries.length < 1) {
    return (
      <Container>
        <header>
          <h1>Visão geral das indústrias</h1>
          <div>
            <label htmlFor="year">Ano de referência: </label>
            <select
              name="year"
              id="year"
              value={year}
              onChange={(e): void => {
                setYear(Number(e.target.value));
              }}
            >
              {years.map(year_item => (
                <option key={year_item} value={year_item}>
                  {year_item}
                </option>
              ))}
            </select>
          </div>
        </header>

        <div
          style={{
            textAlign: "center",
          }}
        >
          <img src={notFoundImage} alt="not found" width={120} height={120} />
          <h2>Nada cadastrado ainda</h2>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <header>
        <h1>Visão geral das indústrias</h1>
        <div>
          <label htmlFor="year">Ano de referência: </label>
          <select
            name="year"
            id="year"
            value={year}
            onChange={(e): void => {
              setYear(Number(e.target.value));
            }}
          >
            {years.map(year_item => (
              <option key={year_item} value={year_item}>
                {year_item}
              </option>
            ))}
          </select>
        </div>
      </header>

      <Table>
        <thead>
          <tr>
            <th>CNPJ || Indústria </th>
            <th>Quantidade de pedidos</th>
            <th>Quantidade (TN) total pedido</th>
            <th>Quantidade (TN) total entregue</th>
            <th>Saldo (TN)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {industries.map(demand => (
            <tr key={demand.id}>
              <td>
                {demand.cnpj} || {demand.name || "Sem nome ainda"}
              </td>
              <td>{demand.demands}</td>
              <td>{demand.demand_total}</td>
              <td>{demand.order_total}</td>
              <td>{demand.balance}</td>
              <td>
                <Link
                  to={`/reports/industries/${demand.id}?year=${year}`}
                  // target="_blank"
                >
                  <span>Visualizar pedidos</span>
                  <RiExternalLinkLine />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Home;
