import styled from "styled-components";

export const Container = styled.aside`
  display: flex;
  justify-content: center;

  margin: 8px;
  padding: 8px;
  border-radius: 8px;

  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.4);

  form {
    display: grid;

    grid-template-columns: repeat(3, minmax(120px, 240px));
    gap: 16px 16px;

    max-width: 940px;

    button {
      padding: 8px 16px;
      border: 0;
      align-self: flex-end;
      background: #c7c08c;
      color: #3b9974;

      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;

      margin-left: 32px;
    }
  }
`;
