import React from "react";
import Lottie from "react-lottie";

import loadingData from "../../assets/loading-files.json";
import { Container } from "./styles";

interface ILoadingProps {
  message: string;
}

export default function Loading({ message }: ILoadingProps) {
  return (
    <Container>
      <header>
        <h2 style={{ textAlign: "center" }}>{message}</h2>
      </header>
      <div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          isClickToPauseDisabled={true}
          style={{
            cursor: "auto",
          }}
        />
      </div>
    </Container>
  );
}
