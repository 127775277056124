import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../components/Form/Input";
import { useAuth } from "../../hooks/auth";
import api from "../../service/api";
import getValidationErrors from "../../utils/getValidationErrors";
import handleMessageError from "../../utils/handleMessageError";
import { Container } from "./styles";

const Configs: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();

  const handleSubmit = useCallback(
    async data => {
      const schema = Yup.object().shape({
        password: Yup.string().required("Senha atual é obrigatória"),
        new_password: Yup.string().required("Nova senha é obrigatória"),
        confirmed_password: Yup.string()
          .oneOf([Yup.ref("new_password")], "As senhas devem ser iguais ")
          .required("Confirmação de senha é obrigatório"),
      });

      formRef.current?.setErrors({});

      try {
        await schema.validate(data, { abortEarly: false });

        await api.patch("/admins", data);

        toast.success("Dados atualizados com sucesso!");

        formRef.current?.reset({ email: user.email });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          toast.error("Favor, verifique os campos");

          formRef.current?.setErrors(errors);

          return;
        }

        toast.error(
          `Falha ao atualizar informações. Favor, tente novamente! ${handleMessageError(
            err,
          )}`,
        );
      }
    },
    [user.email],
  );

  return (
    <Container>
      <header>
        <h2>Perfil</h2>
      </header>
      <main>
        <h3>Dados de acesso a conta</h3>
        <hr />
        {/*
        // @ts-expect-error tipagem do unform desatualizada */}
        <Form onSubmit={handleSubmit} id="access_form" ref={formRef}>
          <Input
            label="E-mail"
            name="email"
            disabled
            defaultValue={user.email}
          />
          <Input
            label="Senha atual"
            name="password"
            type="password"
            placeholder="Digite sua senha atual"
          />
          <Input
            label="Nova senha"
            name="new_password"
            type="password"
            placeholder="Digite sua nova senha"
          />
          <Input
            label="Confirmação de senha"
            name="confirmed_password"
            placeholder="Confirme sua nova senha"
            type="password"
          />
        </Form>
        <div>
          <button type="submit" form="access_form">
            Atualizar dados de acesso
          </button>
        </div>
      </main>
    </Container>
  );
};

export default Configs;
