import styled from "styled-components";

export const Container = styled.ul`
  margin-top: 24px;
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #444;

    & + li {
      margin-top: 16px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
      color: #999;
      margin-top: 4px;
    }
  }

  svg {
    font-size: 36px;
    margin-right: 8px;
  }
`;
