import { Link as LinkRouter } from "react-router-dom";

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  header {
    h1 {
      margin-bottom: 8px;
    }

    margin-bottom: 24px;
  }
`;

export const Table = styled.table`
  width: 100%;

  color: #000;
  text-align: center;
  border: 2px solid #c7c08c;

  border-collapse: collapse;

  table,
  th,
  td {
    border: 1px solid #c7c08c;
  }

  th {
    padding: 4px 16px;
  }
  td {
    padding: 4px;
  }
`;

export const Link = styled(LinkRouter)`
  display: flex;
  align-items: center;

  text-decoration: none;

  svg {
    font-size: 18px;
    margin-left: 4px;
  }
`;
