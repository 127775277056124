import React, { useEffect, useState } from "react";
import { RiEdit2Line, RiExternalLinkLine } from "react-icons/ri";
import { toast } from "react-toastify";

import notFoundImage from "../../assets/not-found.svg";
import Loading from "../../components/Loaging";
import api from "../../service/api";
import formatDate from "../../utils/formatDate";
import handleMessageError from "../../utils/handleMessageError";
import { Container, Table, Link } from "./styles";

interface IDemandsData {
  id: string;
  pedido_id: string;
  type_product: string;
  quantity: string;
  pedido_created_at: string;
  created_at: string;
  created_at_formatted: Date;
  updated_at: string;
}

const Demands: React.FC = () => {
  const [demands, setDemands] = useState<IDemandsData[]>([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/demands?year=${year}`)
      .then(response => {
        setDemands(response.data);
      })
      .catch(err => {
        toast.error(
          `Erro ao carregar pedidos, favor tente novamente! ${handleMessageError(
            err,
          )}`,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [year]);

  if (loading) {
    return <Loading message="Buscando pedidos..." />;
  }

  return (
    <Container>
      <header>
        <div>
          <label htmlFor="year">Ano de referência: </label>
          <select
            name="year"
            id="year"
            value={year}
            onChange={(e): void => {
              setYear(Number(e.target.value));
            }}
          >
            <option value={2025}>2025</option>
            <option value={2024}>2024</option>
            <option value={2023}>2023</option>
            <option value={2022}>2022</option>
            <option value={2021}>2021</option>
          </select>
        </div>
      </header>

      {demands.length > 0 ? (
        <main>
          <Table>
            <thead>
              <tr>
                <th>Número do pedido</th>
                <th>Tipo do produto</th>
                <th>Quantidade (TN)</th>
                <th>Data do pedido</th>
                <th>Criado em</th>
                <th colSpan={2}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {demands.map(demand => (
                <tr key={demand.id}>
                  <td>{demand.pedido_id}</td>
                  <td>{demand.type_product}</td>
                  <td>{demand.quantity}</td>
                  <td>{formatDate(demand.pedido_created_at)}</td>
                  <td>{formatDate(demand.created_at)}</td>
                  <td>
                    <Link to={`/demands/${demand.id}/orders`} target="_blank">
                      <span>Visualizar pedido</span>
                      <RiExternalLinkLine />
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/register-demand/${demand.id}`}
                      style={{ textAlign: "center" }}
                    >
                      <span>Editar pedido</span>
                      <RiEdit2Line />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </main>
      ) : (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img src={notFoundImage} alt="not found" width={120} height={120} />
          <h2>Nada cadastrado ainda</h2>
        </div>
      )}
    </Container>
  );
};

export default Demands;
