import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiFileChartLine,
} from "react-icons/ri";

import { Container, FileInfo } from "./styles";

interface IUploadFilesData {
  file: File;
  id: number;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  messageError?: string;
}

interface IFileListProps {
  files: IUploadFilesData[];
}

const FileList: React.FC<IFileListProps> = ({ files }) => {
  return (
    <Container>
      {files.map(file => (
        <li key={file.id}>
          <FileInfo>
            <RiFileChartLine />
            <div>
              <strong>{file.name}</strong>
              <span>{file.readableSize}</span>
            </div>
          </FileInfo>

          <div>
            {!file.uploaded && !file.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: "#c7c08c" },
                }}
                strokeWidth={10}
                value={file.progress}
              />
            )}

            {file.uploaded && (
              <RiCheckboxCircleLine size={32} color="#78e5d5" />
            )}

            {file.error && (
              <abbr title={file.messageError}>
                <RiCloseCircleLine size={32} color="#e57878" />
              </abbr>
            )}

            {/* <RiErrorWarningLine size={24} color="#f8e153" /> */}
          </div>
        </li>
      ))}
    </Container>
  );
};

export default FileList;
