import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";

import { Form } from "@unform/web";

import notFoundImage from "../../assets/not-found.svg";
import Input from "../../components/Form/Input";
import Loading from "../../components/Loaging";
import api from "../../service/api";
import formatNumber from "../../utils/formatNumber";
import handleMessageError from "../../utils/handleMessageError";
import { Container, Table } from "./styles";

interface ICustomerData {
  cpf: string;
  default_password: string;
  name: string;
  email: string;
  phone: string;
}

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<ICustomerData[]>([]);
  const [customersFiltered, setCustomersFiltered] =
    useState<ICustomerData[]>(customers);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get("/customers")
      .then(response => {
        setCustomers(response.data);
        setCustomersFiltered(response.data);
      })
      .catch(err => {
        toast.error(
          `Erro ao carregar pedidos, favor tente novamente! ${handleMessageError(
            err,
          )}`,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = useCallback(
    ({ search }) => {
      if (!search) {
        setCustomersFiltered(customers);

        return;
      }

      setCustomersFiltered(
        customers.filter(customer => {
          if (
            customer.name
              ?.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()) ||
            customer.cpf.includes(search) ||
            customer.email
              ?.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          )
            return customer;
        }),
      );
    },
    [customers],
  );

  if (loading) {
    return <Loading message="Buscando clientes..." />;
  }

  if (customers.length < 1) {
    return (
      <Container>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img src={notFoundImage} alt="not found" width={120} height={120} />
          <h2>Nada cadastrado ainda</h2>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <header>
        {/*
        // @ts-expect-error tipagem do unform desatualizada */}
        <Form onSubmit={handleSubmit}>
          <p>
            Você pode buscar por <strong>Nome</strong> - <strong>CPF</strong> -{" "}
            <strong>E-mail</strong>
          </p>
          <Input label="" name="search" type="search" autoComplete="off" />

          <button type="submit">Buscar</button>
        </Form>
      </header>

      <main>
        <Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              {/* <th>Telefone</th> */}
              {/* <th>E-mail</th> */}
              <th>Senha padrão</th>
            </tr>
          </thead>
          <tbody>
            {customersFiltered.map(customer => (
              <tr key={customer.cpf}>
                <td>{customer.name || "-"}</td>
                <td>
                  {formatNumber({ data: customer.cpf, type: "cpf" }) || "-"}
                </td>
                {/* <td>{customer.phone || "-"}</td> */}
                {/* <td>{customer.email || "-"}</td> */}
                <td>{customer.default_password || "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </main>
    </Container>
  );
};

export default Customers;
