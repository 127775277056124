import React, { useCallback, useState } from "react";

import { filesize } from "filesize";

import FileList from "../../components/FileList";
import Upload from "../../components/Upload";
import api from "../../service/api";
import { Container } from "./styles";

interface IUploadFilesData {
  file: File;
  id: number;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  messageError?: string;
}

const ElectronicInvoice: React.FC = () => {
  const [uploadFiles, setUploadFiles] = useState<IUploadFilesData[]>([]);

  const updateFile = useCallback((id: any, data: any) => {
    setUploadFiles(state =>
      state.map(file => {
        return id === file.id ? { ...file, ...data } : file;
      }),
    );
  }, []);

  const processUpload = useCallback(
    async (file: IUploadFilesData): Promise<void> => {
      const data = new FormData();

      data.append("file", file.file, file.name);

      try {
        await api.post("/send-file", data, {
          onUploadProgress: e => {
            const progress = Math.round((e.loaded * 100) / (e.total || 0));

            updateFile(file.id, {
              progress,
            });
          },
        });

        updateFile(file.id, {
          uploaded: true,
        });
      } catch (err: any) {
        updateFile(file.id, {
          messageError: err?.response?.data?.message,
          error: true,
        });
      }
    },
    [updateFile],
  );

  const handleUpload = useCallback(
    async (files: any[]) => {
      const uploadedFiles = files.map((file: File) => ({
        file,
        id: Math.random(),
        name: file.name,
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
      }));

      setUploadFiles(state => [...state, ...uploadedFiles]);

      // await Promise.all(uploadedFiles.map(processUpload));

      for (let index = 0; index < uploadedFiles.length; index += 1) {
        const file = uploadedFiles[index];

        // eslint-disable-next-line no-await-in-loop
        await processUpload(file);
      }
    },
    [processUpload],
  );

  return (
    <Container>
      <Upload onUpload={handleUpload} />
      {!!uploadFiles.length && <FileList files={uploadFiles} />}
    </Container>
  );
};

export default ElectronicInvoice;
