import React from "react";
import { Redirect, Switch } from "react-router-dom";

import SignIn from "../pages/Auth/SignIn";
import Configs from "../pages/Configs";
import Customers from "../pages/Customers";
import DemandOrders from "../pages/DemandOrders";
import Demands from "../pages/Demands";
import ElectronicInvoice from "../pages/ElectronicInvoice";
import Home from "../pages/Home";
import RegisterDemand from "../pages/RegisterDemand";
import ReportIndustry from "../pages/Reports/Industry";
import ReportIndustryShipments from "../pages/Reports/Industry/Shipments";
import Route from "./Route";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/sign-in" component={SignIn} />

    <Route isPrivate exact path="/" component={Home} />
    <Route isPrivate exact path="/register-demand" component={RegisterDemand} />
    <Route isPrivate path="/register-demand/:id" component={RegisterDemand} />
    <Route isPrivate exact path="/demands" component={Demands} />
    <Route isPrivate path="/demands/:id/orders" component={DemandOrders} />
    <Route
      isPrivate
      exact
      path="/reports/industries/:id"
      component={ReportIndustry}
    />
    <Route
      isPrivate
      path="/reports/industries/:industry_id/orders/:order_id"
      component={ReportIndustryShipments}
    />
    <Route
      isPrivate
      path="/upload-electronic-invoice"
      component={ElectronicInvoice}
    />
    <Route isPrivate path="/customers" component={Customers} />
    <Route isPrivate path="/configs" component={Configs} />

    <Redirect to="/" />
  </Switch>
);

export default Routes;
