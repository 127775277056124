import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import notFoundImage from "../../../../assets/not-found.svg";
import api from "../../../../service/api";
import formatDate from "../../../../utils/formatDate";
import handleMessageError from "../../../../utils/handleMessageError";
import { Container, Table } from "./styles";
import { format, parseISO } from "date-fns";
import formatNumber from "../../../../utils/formatNumber";

interface IOrderResponse {
  id: number;
  infNFe_id: string;
  product: {
    quantity: number;
    unitary_value?: number;
  };
  shipping: {
    loaded_at: string;
  };
}
interface IOrderData {
  id: number;
  infNFe_id: string;
  product: {
    quantity: number;
    unitary_value: number;
    unitaryValueFormatted: string;
    amount: string;
  };
  shipping: {
    loaded_at: string;
    loadedAtFormatted: string;
  };
}

interface IRouteParams {
  industry_id: string;
  order_id: string;
}

const ReportIndustryShipments: React.FC = () => {
  const [shipments, setShipments] = useState<IOrderData[]>();
  const { industry_id, order_id } = useParams() as IRouteParams;
  const { search } = useLocation();
  const year = search.split("=")[1];

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get<IOrderResponse[]>(
          `/reports/industries/${industry_id}/orders/${order_id}${search}`,
        );

        const shipments_formatted = response.data.map(shipment => {
          let amount = "-";

          if (shipment.product.unitary_value) {
            amount = formatNumber({
              data: shipment.product.unitary_value * shipment.product.quantity,
              type: "currency",
            });
          }

          return {
            ...shipment,
            product: {
              quantity: shipment.product.quantity,
              unitaryValueFormatted: formatNumber({
                data: shipment.product.unitary_value || 0,
                type: "currency",
              }),

              unitary_value: shipment.product.unitary_value || 0,
              amount,
            },
            shipping: {
              loaded_at: shipment.shipping.loaded_at,
              loadedAtFormatted: formatDate(shipment.shipping.loaded_at),
            },
          };
        });

        setShipments(shipments_formatted);
      } catch (err) {
        toast.error(
          `Erro ao carregar informações. Favor, tente novamente${handleMessageError(
            err,
          )}`,
        );
      }
    }

    loadData();
  }, [industry_id, order_id, search]);

  if (!shipments) {
    return (
      <Container>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img src={notFoundImage} alt="not found" width={120} height={120} />
          <h2>Nada cadastrado ainda</h2>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <header>
        <h1>Carregamentos do pedido: {order_id}</h1>

        <p>
          Ano de referência: <strong>{year}</strong>
        </p>
      </header>

      <main>
        {shipments.length === 0 ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img src={notFoundImage} alt="not found" width={120} height={120} />
            <h2>Nenhum carregamento cadastrado</h2>
          </div>
        ) : (
          TabelaPedidos(groupByDay(shipments))
        )}
      </main>
    </Container>
  );
};

// Função para agrupar carregamentos por dia
const groupByDay = (orders: IOrderData[]) => {
  const groupedOrders: { [key: string]: IOrderData[] } = {};

  orders.forEach(order => {
    console.log({ order });

    const date = format(parseISO(order.shipping.loaded_at), "yyyy-MM-dd");

    if (!groupedOrders[date]) {
      groupedOrders[date] = [];
    }
    groupedOrders[date].push(order);
  });

  return groupedOrders;
};

const TabelaPedidos = (groupedOrders: any) => (
  <Table>
    <thead>
      <tr>
        <th>Número da Nota Fiscal</th>
        <th>Data do carregamento</th>
        <th>Quantidade (TN) carregado</th>
        <th>Valor unitário</th>
        <th>Valor total</th>
      </tr>
    </thead>

    <tbody>
      {Object.entries(groupedOrders).map((params: any) => {
        const [date, shipments] = params as [
          date: string,
          shipments: IOrderData[],
        ];

        const totalValue = shipments.reduce(
          (total, order) =>
            total + order.product.unitary_value * order.product.quantity,
          0,
        );

        const totalLoaded = shipments.reduce(
          (total, order) => total + order.product.quantity,
          0,
        );

        return (
          <React.Fragment key={date}>
            {shipments.map(shipment => (
              <tr key={shipment.id}>
                <td>{shipment.infNFe_id}</td>
                <td>{shipment.shipping.loadedAtFormatted}</td>

                <td>{shipment.product.quantity}</td>
                <td>{shipment.product.unitaryValueFormatted}</td>
                <td>{shipment.product.amount}</td>
              </tr>
            ))}

            <tr>
              <td colSpan={2} style={{ textAlign: "right" }}>
                <strong>
                  Total do dia {parseISO(date).toLocaleDateString()}
                </strong>
              </td>

              <td>
                <strong>
                  {Intl.NumberFormat("pt-br").format(totalLoaded)}
                </strong>
              </td>

              <td></td>

              <td>
                <strong>
                  {formatNumber({ data: totalValue, type: "currency" })}
                </strong>
              </td>
            </tr>
          </React.Fragment>
        );
      })}
    </tbody>
  </Table>
);

export default ReportIndustryShipments;
