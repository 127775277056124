import styled from "styled-components";

export const Container = styled.div`
  header {
    margin-bottom: 16px;
  }

  main {
    h3 {
      margin: 0 0 8px 16px;
    }

    hr {
      height: 1px;
      border: 0;

      background: #c7c08c;
    }

    form {
      display: grid;
      grid-template-areas:
        "input1 input2"
        "input3 input4"
        "button .";
      gap: 8px 24px;

      max-width: 480px;

      margin: 16px auto;
    }

    > div {
      display: flex;

      button {
        margin: 0 auto;
        padding: 8px 16px;
        border: 0;

        border-radius: 8px;

        font-size: 18px;
        background: #c7c08c;
        color: #3b9974;

        transition: opacity 0.2s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
