import styled, { css } from "styled-components";

interface IDropContainerProps {
  isDragActive: boolean;
  isDragReject: boolean;
}

interface IUploadMessageProps {
  type?: "error" | "success";
}

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div<IDropContainerProps>`
  width: 100%;

  border: 2px dashed #ddd;
  border-radius: 4px;

  cursor: pointer;

  transition: height 0.2s ease;

  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
`;

const messageColors = {
  default: "#999",
  error: "#e57878",
  success: "#78e5d5",
};

export const UploadMessage = styled.div<IUploadMessageProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 0;
  text-align: center;
  color: ${props => messageColors[props.type || "default"]};

  svg {
    font-size: 32px;
    margin-bottom: 8px;
  }
`;
