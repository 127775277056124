import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppProvider from "./hooks";
import Routes from "./routes";
import GlobalStyle from "./styles/GlobalStyle";

const App: React.FC = () => (
  <>
    <GlobalStyle />
    <ToastContainer />
    <AppProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AppProvider>
  </>
);

export default App;
