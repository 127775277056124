import { Link as LinkRouter } from "react-router-dom";

import styled from "styled-components";

export const Container = styled.aside`
  display: flex;
  flex-direction: column;

  header {
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.4);
    padding: 8px;
    border-radius: 8px;
    max-width: 350px;
  }

  margin: 8px;
  border-radius: 8px;

  form {
    display: flex;

    div + div {
      margin-left: 8px;
    }

    button {
      padding: 8px 16px;
      border: 0;
      align-self: flex-end;
      background: #c7c08c;
      color: #3b9974;

      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;

      margin-left: 32px;
    }
  }

  main {
    margin-top: 32px;
  }
`;

export const Table = styled.table`
  width: 100%;

  color: #000;
  text-align: center;
  border: 2px solid #c7c08c;

  border-collapse: collapse;

  table,
  th,
  td {
    border: 1px solid #c7c08c;
  }

  th {
    padding: 4px 16px;
  }
  td {
    padding: 4px;
  }
`;

export const Link = styled(LinkRouter)`
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  svg {
    font-size: 18px;
    margin-left: 4px;
  }
`;
