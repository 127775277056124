import React from "react";
import { CgUserList } from "react-icons/cg";
import {
  RiDashboardLine,
  RiLogoutCircleLine,
  RiMenuFill,
  RiOrderPlayFill,
  RiSettings4Line,
  RiUploadCloud2Line,
} from "react-icons/ri";

import { useAuth } from "../../hooks/auth";
import { Container, Footer, Link } from "./styles";

const Sidebar: React.FC = () => {
  const { signOut } = useAuth();

  return (
    <Container>
      <header>
        <p>
          Central <strong>Calcário</strong>
        </p>
      </header>
      <Link to="/">
        <RiDashboardLine />
        <p>Home</p>
      </Link>
      <Link to="/register-demand">
        <RiOrderPlayFill />
        <p>Registrar Pedido</p>
      </Link>
      <Link to="/demands">
        <RiMenuFill />
        <p>Pedidos cadastrados</p>
      </Link>
      <Link to="/upload-electronic-invoice">
        <RiUploadCloud2Line />
        <p>Cadastrar Notas Fiscais</p>
      </Link>
      <Link to="/customers">
        <CgUserList />
        <p>Clientes</p>
      </Link>

      <div>
        <Link to="/configs">
          <RiSettings4Line />
          <p>Configurações</p>
        </Link>
      </div>

      <Footer>
        <button onClick={signOut}>
          <RiLogoutCircleLine />
          <p>Sair</p>
        </button>
      </Footer>
    </Container>
  );
};

export default Sidebar;
