import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../../components/Form/Input";
import { useAuth } from "../../../hooks/auth";
import getValidationErrors from "../../../utils/getValidationErrors";
import { Container } from "./styles";

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async data => {
      const schema = Yup.object().shape({
        email: Yup.string().email().required("Campo e-mail é obrigatório"),
        password: Yup.string().required("Campo senha é obrigatório"),
      });

      formRef.current?.setErrors({});

      try {
        await schema.validate(data, { abortEarly: false });

        await signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          toast.error("Favor, verifique os campos");

          formRef.current?.setErrors(errors);
          return;
        }
        toast.error("Falha ao fazer login. Favor, tente novamente");
      }
    },
    [signIn],
  );

  return (
    <Container>
      {/*
        // @ts-expect-error tipagem do unform desatualizada */}
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Input
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail"
          type="email"
        />
        <Input
          label="Senha"
          name="password"
          placeholder="Digite sua senha"
          type="password"
        />

        <button type="submit">Entrar</button>
      </Form>
    </Container>
  );
};

export default SignIn;
