import React from "react";

import Sidebar from "../../components/Sidebar";
import { Container, Wrapper } from "./styles";

export default function DefaultLayout({ children }) {
  return (
    <Container>
      <Sidebar />
      <Wrapper>{children}</Wrapper>
    </Container>
  );
}
