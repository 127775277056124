import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  label {
    color: #000;
  }

  input {
    margin-top: 4px;

    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #000;
    width: 100%;

    font-size: 16px;
  }

  span {
    margin-top: 4px;
    color: red;
  }
`;
