import React, {
  ReactText,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../components/Form/Input";
import api from "../../service/api";
import { cnpjValidation } from "../../utils/cnpjValidation.js";
import { cpfValidation } from "../../utils/cpfValidation.js";
import getValidationErrors from "../../utils/getValidationErrors";
import handleMessageError from "../../utils/handleMessageError";
import { Container } from "./styles";

interface IFormData {
  pedido_id: string;
  customer_cpf: string;
  industry_cnpj: string;
  type_product: string;
  quantity: number;
  amount: number;
  pedido_created_at: string;
}

interface IIndustryData {
  id: string;
  cnpj: string;
  name?: string;
}
interface IClientData {
  id: string;
  cpf: string;
  name: string;
}

interface IDemandResponse {
  pedido_id: string;
  type_product: string;
  quantity: number;
  amount?: number;
  pedido_created_at?: string;
  customer_cpf?: string;
  industry_cnpj?: string;
}

interface IRouteParams {
  id: string;
}

const RegisterDemand: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [industries, setIndustries] = useState<IIndustryData[]>([]);
  const [clients, setClients] = useState<IClientData[]>([]);
  const [typesProducts, setTypesProducts] = useState<string[]>([]);

  const { id } = useParams() as IRouteParams;

  const loadDataToRegisters = useCallback(() => {
    api
      .get("/industries")
      .then(response => {
        setIndustries(response.data);
      })
      .catch(err => {
        toast.error(
          `Erro ao carregar informações, atualize a página! Erro: ${handleMessageError(
            err,
          )}`,
        );
      });

    api
      .get("/customers")
      .then(response => {
        setClients(response.data);
      })
      .catch(err => {
        toast.error(
          `Erro ao carregar informações, atualize a página! Erro: ${handleMessageError(
            err,
          )}`,
        );
      });

    api
      .get("/demands", {
        params: {
          type_products: true,
        },
      })
      .then(response => {
        setTypesProducts(response.data);
      })
      .catch(err => {
        toast.error(
          `Erro ao carregar informações, atualize a página! Erro: ${handleMessageError(
            err,
          )}`,
        );
      });
  }, []);

  useEffect(() => {
    loadDataToRegisters();
  }, [loadDataToRegisters]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const toastInfo = toast.info("Carregando informações");

    api
      .get<IDemandResponse>(`/demands/${id}`)
      .then(response => {
        const { pedido_created_at } = response.data;

        const data = {
          ...response.data,
          pedido_created_at: pedido_created_at?.split("T")[0],
        };

        formRef.current?.setData(data);
      })
      .then(() => {
        toast.dismiss(toastInfo);
      })
      .catch(err => {
        toast.update(toastInfo, {
          render: `Erro ao carregar informações do pedido, favor atualize a página ou tente mais tarde! Erro: ${handleMessageError(
            err,
          )}`,
          type: "error",
          autoClose: 10000,
        });
      });
  }, [id]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      const {
        amount,
        customer_cpf,
        industry_cnpj,
        pedido_created_at,
        pedido_id,
        quantity,
        type_product,
      } = data;

      const data_formatted = {
        amount: Number(amount),
        customer_cpf,
        industry_cnpj,
        pedido_created_at,
        pedido_id,
        quantity: Number(quantity),
        type_product,
      };
      console.log(data_formatted);

      formRef.current?.setErrors({});

      let toastStatus: ReactText = "status";

      try {
        const schema = Yup.object().shape({
          pedido_id: Yup.string().required("ID do pedido é obrigatório"),
          type_product: Yup.string().required("Tipo do produto é obrigatório"),
          quantity: Yup.string().required("Quantidade é obrigatório"),
          customer_cpf: Yup.string()
            .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
            .required("Campo obrigatório")
            .test("test-cpf", "CPF deve ser válido", value => {
              if (value) {
                return cpfValidation(value);
              }

              return false;
            }),
          industry_cnpj: Yup.string()
            .matches(/^[0-9]+$/, "Deve ser apenas dígitos")
            .required("Campo obrigatório")
            .test("test-cnpj", "CNPJ deve ser válido", value => {
              if (value) {
                return cnpjValidation(value);
              }

              return false;
            }),
          amount: Yup.string().required("Campo obrigatório"),
          pedido_created_at: Yup.date().required("Campo obrigatório"),
        });

        await schema.validate(data, { abortEarly: false });

        toastStatus = toast(`Cadastrando pedido ${pedido_id}`, {
          type: "info",
        });

        if (id) {
          await api.put(`/demands/${id}`, data_formatted);
        } else {
          await api.post("/demands", data_formatted);
        }

        loadDataToRegisters();

        toast.update(toastStatus, {
          type: "success",
          render: `Pedido ${pedido_id} ${
            id ? "atualizado" : "cadastrado"
          } com sucesso`,
        });

        formRef.current?.reset();

        const inputPedidoRef = formRef.current?.getFieldRef("pedido_id");

        inputPedidoRef.focus();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          toast.error("Erro ao criar registro. Favor, verifique os campos!");

          return;
        }

        toast.update(toastStatus, {
          type: "error",
          render: `Erro ao criar o registro do pedido. ${handleMessageError(
            error,
          )}`,
        });
      }
    },
    [id, loadDataToRegisters],
  );

  return (
    <Container>
      {/*
        // @ts-expect-error tipagem do unform desatualizada */}
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Input
          label="Número do pedido"
          name="pedido_id"
          autoComplete="off"
          placeholder="Digite o número do pedido"
        />
        <Input
          label="Data de cadastro do pedido"
          name="pedido_created_at"
          type="date"
          required
        />
        <Input
          label="CPF do cliente"
          name="customer_cpf"
          autoComplete="off"
          placeholder="Digite o CPF ou nome"
          list="list_clients"
        />

        <datalist id="list_clients">
          {clients.map(client => (
            <option key={client.id} value={client.cpf} label={client.name} />
          ))}
        </datalist>

        <Input
          label="CNPJ da indústria"
          name="industry_cnpj"
          autoComplete="off"
          placeholder="Digite o CNPJ ou nome"
          list="list_industries"
        />

        <datalist id="list_industries">
          {industries.map(industry => (
            <option
              key={industry.id}
              value={industry.cnpj}
              label={industry.name}
            />
          ))}
        </datalist>

        <Input
          label="Tipo do produto"
          name="type_product"
          autoComplete="off"
          placeholder="Digite o nome do produto comprado"
          list="types_products"
        />
        <datalist id="types_products">
          {typesProducts.map(type => (
            <option key={type} value={type} />
          ))}
        </datalist>

        <Input
          label="Quantidade (TN)"
          name="quantity"
          autoComplete="off"
          type="number"
          step="any"
          placeholder="Digite a quantidade comprada em Toneladas"
        />
        <Input
          label="Valor total do pedido"
          name="amount"
          autoComplete="off"
          placeholder="Digite o valor"
        />

        <button type="submit">{id ? "Atualizar" : "Cadastrar"}</button>
      </Form>
    </Container>
  );
};

export default RegisterDemand;
