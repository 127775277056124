import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  form {
    width: 100%;
    max-width: 304px;

    div + div {
      margin-top: 8px;
    }

    button {
      border: 0;
      width: 100%;
      margin-top: 16px;

      padding: 8px;

      background: #f7e3ca;

      color: #348f6c;
      font-size: 18px;
      font-weight: 700;
      border-radius: 8px;

      transition: opacity 0.2s ease-in;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
